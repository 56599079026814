.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-overlay {
  position: relative;
  overflow: hidden;
}

.blur-text {
  filter: blur(5px);
  transition: filter 0.3s;
}

.show-more-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.show-more-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

@keyframes breathEffect {
  0% {
      box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.377);
  }
  100% {
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
}

.breath-button {
  border: 2px solid rgba(255, 0, 0, 0.411); /* 或者您喜欢的颜色 */
  margin: 5px;
  animation: breathEffect 2s ease-out infinite;
}


.centered-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.card {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}
.card-2 {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}
.card-3 {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 30vh;
}

.card-5 {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 80vh;
}
.token-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px; 
}

.token-container {
  flex-grow: 1;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  overflow-x: auto;
}

.token {
  white-space: nowrap;
}

.btn-copy {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-copy:hover {
  background: #0056b3;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

/* app.css */
@keyframes scroll-text {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.scroll-animation {
  animation: scroll-text 15s linear infinite;
}

